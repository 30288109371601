import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lutein-howto-header.jpg"
import LuteinComplexTablet from "./../../images/lutein-howto-header.jpg"
import LuteinComplexDesktop from "./../../images/lutein-howto-header.jpg"
import LuteinComplexXL from "./../../images/lutein-howto-header.jpg"

import lutShield from "./../../images/lutein-howto-feature1.jpg"
import warningSign from "./../../images/lutein-howto-no1.svg"

import lutein from "./../../images/lut-virusi-lutein-complex.jpg"


const LuteinHowTo = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-howto" }}>
    <Seo title="Как се прави здраво око?" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>КАК СЕ ПРАВИ ЗДРАВО ОКО?</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Как се прави здраво око?"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Как се прави здраво око?
          </h1>
          <h2 className="font-light ">
            <em>Слабото зрение води до още по-голяма изолация</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Отслабеното зрение не е просто досадно неудобство. В по-напреднала
            фаза то се превръща в истински ад, който ни изолира от света, докато
            накрая не заживеем съвсем самотни. Зависими от всякакви „патерици“ –
            очила, лупи, чужди млади очи – често оставаме слепи за нещата покрай
            нас. И най-лошото – свикваме да е така.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={6}>
          <h4>Ще видим ли света с нови очи?</h4>
          <p>
            Буквите „танцуват“, цената на етикета е размазана, а очилата вечно
            ги търсим, дори да са на главата ни. Има начин обаче да се отървем
            от тази досада и то не непременно подлагайки се под ножа, както е
            модерно.
          </p>
          <p>
            Много по-щадящо и лесно е просто да дадем на очите си нужното, за да
            не ни предадат без време. Но не просто „нещо“, а достатъчно
            премерена сила, която да окаже реален ефект. Проучването ни сочи, че
            на това условие отговаря разработка, родена във Франция. Уникалната
            формула, позната под името ЛУТЕИН КОМПЛЕКС ПЛЮС, в три стъпки дава
            такива резултати, че да видим света с нови очи.
          </p>
        </Col>
        <Col sm={6}>
          <Image className="w-100 rounded float-right" src={lutShield} />
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Здрави очи: Стъпка 1: „Зареждане“</h4>
          <p>
            Съществува ли съвременен телефон без зарядно? Същото е с очите ни –
            трябва да ги „включим“ в „тока“, за да работят.
          </p>
          <p>
            Служещо като своеобразно зарядно, ЛУТЕИН КОМПЛЕКС ПЛЮС дава на очите
            ни нужната енергия посредством шест специално подбрани съставки,
            дозирани в удобни капсули. Подхранващи ретината, концентрираните
            количества лутеин и зеаксантин подобряват работата ù и едновременно
            с това предпазват окото от вредните външни влияния. За да стигнат
            тези полезни вещества до него обаче, са нужни и здрави капиляри –
            тази важна роля се пада на червената боровинка, добавена като мощен
            екстракт. Накрая, за да затвърди ефекта на всички тези вещества,
            ЛУТЕИН КОМПЛЕКС ПЛЮС дава на зрението ни още бонуси - витамини С, Е
            и цинк.
          </p>
          <p>
            Така, умно поддържано с естествената сила на природата, окото
            най-сетне служи по предназначение – да вижда добре!
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Здрави очи: Стъпка 2: „Осветяване“</h4>

          <p>
            Натрупат ли се достатъчно в организма, експертно подбраните съставки
            скоро започват осезаемо да дават резултати (към третия месец от
            терапевтичния прием). Подобно на отдръпването на завеса, закриваща
            гледката пред нас,{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> „надига“
            надвисналата над зрението ни „мъгла“ и постепенно започва да ни
            светва. Това нормализиране на нещата се отразява и на здравословната
            овлажненост на окото, служеща за смазка против усещания като сухота,
            парене или бодежи. Скоро е налице и промяна в качеството на живота
            ни. Ставаме по-самостоятелни и спокойни, че полагаме нужните грижи
            за един от най-важните ни органи.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Здрави очи: Стъпка 3: „Почистване“</h4>

          <p>
            Очите, казват, били прозорец към света. Но колко ясно виждаме този
            свят, ако „прозорецът“ не е почистван от години? Тук трябва да се
            намеси наистина работещ „препарат за почистване“, при това за да е
            още по-силен ефектът, най-добре да е течен, вместо на „прах“.
            Служейки точно като такъв, благодарение на предварително
            разтворените в масло полезни вещества,{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> се усвоява
            по-бързо от организма, а това естествено води до по-траен и осезаем
            ефект. Това не е случайно, защото тялото е „направено“ така, че да
            предпочита течни форми – много по-лесно ги разпознава и приема. Към
            момента ЛУТЕИН КОМПЛЕКС ПЛЮС е единствената подкрепа за очи в маслен
            вариант, вложен в удобни капсули за ежедневен прием само по една на
            ден.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={10} xs={8}>
          <p>
            
            Най-продаван продукт в категория „Витамини и добавки за очи“ за 2020
            г. по данни на IQVIA.
          </p>
        </Col>
        <Col sm={2} xs={4}>
          <Image className="w-100 rounded float-right" src={warningSign} />
        </Col>
      </Row>
      <Row className="product-container">
        <Col md={{ span: 8, offset: 2 }}>
          <Link to="/lutein-complex">
            <Image className="w-100" src={lutein} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinHowTo
